import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark


  return (
    <Layout>
    {console.log("data", data)}
    <div className="blog-post-container">
      <div className="p-2 m-4 border inline-block">
        <Link to="/">summerset support</Link>
        <span className="mx-3">/</span>
        <Link to={"/" + frontmatter.category}>{frontmatter.category}</Link>
        
        {frontmatter.slug.split("/").filter(e => e).length > 1 ? <><span className="mx-3">/</span><Link to="/">{frontmatter.title}</Link></> : ""}
        {/* <span className="mx-3">/</span> */}
        {/* <span>{frontmatter.title}</span> */}
      </div>
      <div className="blog-post prose prose-md w-3/4 mx-auto mb-10">
        <Link to="../" className="no-underline">&larr;	Back</Link>
        <h1 className="font-light">{frontmatter.title}</h1>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        slug
        category
      }
    }
  }
`